@import 'theme/variable';
$primary: #357fef;
$secondary: #4e4e4e;
$light:#13B8EA;
$dark: #4E56F3;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/nav';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';