nav{
  padding: 30px 0;
  display: flex;
  justify-content: flex-end;
}
.logo{
  align-items: flex-start;
}
.simple-menu{
  min-width: auto!important;
}